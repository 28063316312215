import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  Search,
  LPlan,
  ConceptMedia,
  CLocalNav,
  CCard,
  CLabelCardSlider,
  CBtnList,
  CSectMedia,
  CRoomViewMedia,
  LWifi,
  CNewsList,
  LStayContact,
} from '../../components/_index';
import '../../assets/_sass/page/stay.scss';
import infoChoice from '../../utils/info-choice';
import infoGet from '../../utils/info-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'STAY',
            sub: 'ご宿泊',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/kv__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/stay/kv02.png',
              },
              imgSp: {
                src: '/assets/images/stay/kv02__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/stay/kv03.png',
              },
              imgSp: {
                src: '/assets/images/stay/kv03__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/stay/kv04.png',
              },
              imgSp: {
                src: '/assets/images/stay/kv04__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/stay/kv05.png',
              },
              imgSp: {
                src: '/assets/images/stay/kv05__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'ご宿泊',
            },
          }}
        />
      </CJumbotron>
      <Search formId="toppage" />
      <section>
        <LWrap>
          <div className="p_stayLead">
            <p className="p_stayLead_title">
              WELCOME
              <br />
              YOKOHAMA
              <br />
              SKY
              <br />
              RESORT
            </p>
            <div className="p_stayLead_info">
              <p className="text">
                横浜ランドマークタワー52Fから67Fに位置する603の客室。
                <br />
                広がる空と港町ヨコハマの眺望とともに、非日常の時間をお過ごしください。
              </p>
              <ul className="list">
                <li>
                  基本チェックイン時間
                  <br className="u_pc" />
                  <span>15:00</span>
                </li>
                <li>
                  基本チェックアウト時間
                  <br className="u_pc" />
                  <span>11:00</span>
                </li>
                <li>
                  総部屋数
                  <br className="u_pc" />
                  <span>603室</span>
                </li>
              </ul>
              <ul className="c_noteList">
                <li>
                  チェックインの際、事前精算もしくはホテル規定のデポジットを頂戴しております。
                </li>
                <li>
                  宿泊プランにチェックインまたはチェックアウト時間の規定がある場合は、その規定が優先されます。
                </li>
              </ul>
            </div>
          </div>
          <CLocalNav
            data={[
              {
                img: {
                  src: '/assets/images/stay/img_localnav.png',
                  alt: '',
                },
                link: {
                  href: '/stay/plan/',
                },
                title: {
                  main: 'プラン一覧',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/img_localnav02.png',
                  alt: '',
                },
                link: {
                  href: '/stay/floor/',
                },
                title: {
                  main: '客室一覧',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/img_localnav03.png',
                  alt: '',
                },
                link: {
                  href: '/stay/room_service/',
                },
                title: {
                  main: 'ルームサービス',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/img_localnav04.png',
                  alt: '',
                },
                link: {
                  href: '/anniversary/stay_plan/',
                },
                title: {
                  main: (
                    <>
                      お祝い・
                      <br className="u_pc" />
                      記念日
                    </>
                  ),
                },
              },
              {
                img: {
                  src: '/assets/images/stay/img_localnav05.png',
                  alt: '',
                },
                link: {
                  href: '/stay/relaxation/',
                },
                title: {
                  main: (
                    <>
                      インルーム <br className="u_pc" />
                      リラクゼーション
                    </>
                  ),
                },
              },
              {
                img: {
                  src: '/assets/images/stay/img_localnav06.png',
                  alt: '',
                },
                link: {
                  href: '/restaurants/breakfast/',
                },
                title: {
                  main: '朝食',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/img_localnav07.png',
                  alt: '',
                },
                link: {
                  href: '/stay/floor/club_lounge/',
                },
                title: {
                  main: 'クラブラウンジ',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <LPlan />
      <section className="u_mbLarge">
        <LWrap>
          <CSectTitle title={{ ja: <>客室のご案内</>, en: <>ROOM</> }} />
          <CLabelCardSlider
            data={[
              {
                label: '67F',
                img: {
                  src: '/assets/images/stay/img_room.png',
                  alt: '',
                },
                title: <>スカイリゾートフロア「ザ・トップ」</>,
                link: {
                  href: '/stay/floor/top_floor/',
                },
              },
              {
                label: '65F 〜 66F',
                img: {
                  src: '/assets/images/stay/img_room02.png',
                  alt: '',
                },
                title: <>スカイリゾートフロア「ザ・クラブ」</>,
                link: {
                  href: '/stay/floor/club_floor/',
                },
              },
              {
                label: '65F 〜 66F',
                img: {
                  src: '/assets/images/stay/img_room03.png',
                  alt: '',
                },
                title: <>スイートルーム／和室</>,
                link: {
                  href: '/stay/floor/suite_floor/',
                },
              },
              {
                label: '60F 〜 64F',
                img: {
                  src: '/assets/images/stay/img_room04.png',
                  alt: '',
                },
                title: <>スカイリゾートフロア「アトリエ」</>,
                link: {
                  href: '/stay/floor/atelier_floor/',
                },
              },
              {
                label: '57F 〜 59F',
                img: {
                  src: '/assets/images/stay/img_room05.png',
                  alt: '',
                },
                title: <>レギュラーフロア</>,
                link: {
                  href: '/stay/floor/57_regular_floor/',
                },
              },
              {
                label: '52F 〜 56F',
                img: {
                  src: '/assets/images/stay/img_room06.png',
                  alt: '',
                },
                title: <>レギュラーフロア</>,
                link: {
                  href: '/stay/floor/52_regular_floor/',
                },
              },
            ]}
          />
          <CBtnList
            data={[
              {
                label: '客室一覧',
                link: {
                  href: `/stay/floor/`,
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <div className="u_bgMix l_sect">
        <section id="view">
          <LWrap>
            <CRoomViewMedia
              exClass="u_mb60_pc u_mb80_sp"
              data={[
                {
                  imgList: [
                    {
                      img: {
                        src: '/assets/images/stay/img_view_ne.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/stay/img_view_ne02.png',
                        alt: '',
                      },
                    },
                  ],
                  text: (
                    <>
                      横浜ベイブリッジなど、シーサイドの主な景観がご覧いただける方向です。（臨港パーク　パシフィコ横浜　横浜コスモワールド・大観覧車　横浜ベイブリッジ）
                    </>
                  ),
                },
                {
                  imgList: [
                    {
                      img: {
                        src: '/assets/images/stay/img_view_se.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/stay/img_view_se02.png',
                        alt: '',
                      },
                    },
                  ],
                  text: (
                    <>
                      横浜ベイブリッジ・山下公園・横浜スタジアムなど、港町横浜が一望できる方向です。
                      （大桟橋　赤レンガ倉庫　山下公園　氷川丸　マリンタワー　中華街　横浜スタジアム）
                    </>
                  ),
                },
                {
                  imgList: [
                    {
                      img: {
                        src: '/assets/images/stay/img_view_sw.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/stay/img_view_sw02.png',
                        alt: '',
                      },
                    },
                  ],
                  text: (
                    <>
                      空気の澄んだ日には富士山がご覧いただけます。夜景が綺麗な方向です。
                      （野毛山公園　桜木町駅付近　富士山　丹沢山地　横浜市街地）
                    </>
                  ),
                },
                {
                  imgList: [
                    {
                      img: {
                        src: '/assets/images/stay/img_view_nw.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/stay/img_view_nw02.png',
                        alt: '',
                      },
                    },
                  ],
                  text: (
                    <>
                      横浜駅・みなとみらい21 地区がご覧いただける方向です。
                      （横浜市街地　横浜駅周辺　みなとみらい21地区　東京方面）
                    </>
                  ),
                },
              ]}
            />
            <CSectMedia
              reverse={true}
              data={[
                {
                  img: {
                    src: '/assets/images/stay/img_breakfast.png',
                    alt: '',
                  },
                  title: {
                    ja: '朝食',
                    en: 'BREAKFAST',
                  },
                  text: (
                    <>
                      目の前でシェフが仕上げるオリジナルオムレツや鯛出汁茶漬けなど、洋食だけでなく和食メニューもご用意しております。
                      <br />
                      一日の始まりに、絶景を眺めながら、贅沢なひとときをお楽しみください。
                    </>
                  ),
                  btn: {
                    label: '朝食',
                    link: {
                      href: '/restaurants/breakfast/',
                    },
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/img_club_lounge.png',
                    alt: '',
                  },
                  title: {
                    ja: 'クラブラウンジ',
                    en: 'CLUB LOUNGE',
                  },
                  text: (
                    <>
                      スカイリゾートフロア「ザ・クラブ」にご宿泊のお客様専用のラウンジです。
                      <br />
                      滞在中にご利用いただけるご朝食やお飲物の他、観光のご案内などきめ細かいサービスをご提供いたします。
                    </>
                  ),
                  btn: {
                    label: 'クラブラウンジ',
                    link: {
                      href: '/stay/floor/club_lounge/',
                    },
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="u_mb90">
          <LWrap>
            <CSectTitle
              title={{ ja: <>サービス・その他</>, en: <>SERVICE</> }}
            />
            <CCard
              exClass="u_mb90"
              data={[
                {
                  img: {
                    src: '/assets/images/stay/img_service.png',
                    alt: '',
                  },
                  title: <>ルームサービス</>,
                  content: (
                    <>
                      プライベートな空間でゆったりとお食事をお楽しみください。
                    </>
                  ),
                  link: {
                    href: '/stay/room_service/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/img_service02.png',
                    alt: '',
                  },
                  title: <>お祝い・記念日</>,
                  content: (
                    <>お部屋にお花をご用意。サプライズなどの演出に最適です。</>
                  ),
                  link: {
                    href: '/anniversary/stay_plan/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/img_service03.png',
                    alt: '',
                  },
                  title: <>インルームリラクゼーション</>,
                  content: (
                    <>
                      ご宿泊のお部屋でお受けいただくトリートメントメニューです。
                    </>
                  ),
                  link: {
                    href: '/stay/relaxation/',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section>
          <LWrap>
            <CSectMedia
              reverse={true}
              data={[
                {
                  img: {
                    src: '/assets/images/stay/img_restaurant_bar.png',
                    alt: '',
                  },
                  title: {
                    ja: 'レストラン＆バー',
                    en: (
                      <>
                        RESTAURANT
                        <br />& BAR
                      </>
                    ),
                  },
                  text: (
                    <>
                      横浜ランドマークタワー最上階のスカイラウンジ、68Fの和洋中のレストランをはじめ、ビジネスやプライベートのさまざまなシーンで使える飲食施設をご用意いたしております。
                    </>
                  ),
                  btn: {
                    label: 'レストラン＆バー',
                    link: {
                      href: '/restaurants/',
                    },
                  },
                },
              ]}
            />
          </LWrap>
        </section>
      </div>
      <section className="l_sect">
        <LWrap>
          <CSectTitle title={{ ja: 'お知らせ', en: 'INFORMATION' }} />
          <CNewsList data={infoChoice(infoGet(), ['宿泊'], 5)} label={false} />
          <CBtnList
            data={[
              {
                label: 'お知らせ',
                link: {
                  href: '/information/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LStayContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
